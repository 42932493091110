import { DetailPage as EventDetailPage } from './../../calendar/detail/detail.page';
import { DetailPage as IncidentDetailPage } from './../../incidents/detail/detail.page';
import { DetailPage as ConsultationDetailPage } from './../../consultations/detail/detail.page';
import { ProposalDetailPage } from './../../proposals/proposal-detail/proposal-detail.page';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ModalController, NavController } from '@ionic/angular';
import { EventsService } from 'src/app/services/events.service';
import { NotificationDTO } from 'src/app/models/NotificationDTO';
import { ProposalModel } from 'src/app/models/ProposalModel';
import { ConsultationDTO } from 'src/app/models/ConsultationDTO';
import { AlertModel } from 'src/app/models/AlertModel';
import { ReactionType } from 'src/constants';
import { AuthService } from 'src/app/auth/auth.service';
import { EventDTO } from 'src/app/models/EventDTO';
import * as moment from 'moment';
import { StorageService } from 'src/app/services/storage.service';
import { CommunityService } from 'src/app/services/community/community.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NewDTO } from 'src/app/models/NewDTO';
import { NewsDetailPage } from '../../news/news-detail/news-detail.page';
import { Fancybox } from '@fancyapps/ui';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class DetailPage implements OnInit {

  @Input() id: number;
  notif: NotificationDTO;

  public htmlDescription: boolean;

  public bodyDescription: SafeHtml;

  isLoading = true;
  contentIsLoading = false;

  modalMode = true;

  logoAj: string;

  proposal: ProposalModel;
  consultation: ConsultationDTO;
  alert: AlertModel;
  eventList: Array<EventDTO>;
  featuredNews: Array<NewDTO>;

  reactionType = ReactionType.NOTIFICATION;

  translations = {};

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private modalCtrl: ModalController,
    private events: EventsService,
    private storage: StorageService,
    private nav: NavController,
    private auth: AuthService,
    private modalController: ModalController,
    private community: CommunityService,
    private sanitizer: DomSanitizer,    
  ) { 

  }

  ngOnInit() {

    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.id = parseInt(id);
    }

    this.community.Get().subscribe((c) => {
      let communityId = c.id;
      this.api.geNotificationById(this.id).then((res) => {
        this.notif = res;        
        
        if (res.townId == communityId){
          this.logoAj = c.image;
        } else {
          this.api.Town_GetById(res.townId).then((comm) => {
            this.logoAj = comm.image;
          });
        }

        if (res.body) {
          let isHtml = /<\/?[a-z][\s\S]*>/i.test(res.body);
          if (isHtml) {
            this.htmlDescription = true;
            this.bodyDescription = this.sanitizer.bypassSecurityTrustHtml(res.body);
          } else {
            this.bodyDescription = this.sanitizer.bypassSecurityTrustHtml(this.urlify(res.body));
          }

        }
  
        if (res.proposalId) {
          this.contentIsLoading = true;
          this.api.getProposal(res.proposalId).then(res => {
            this.proposal = res;
            this.contentIsLoading = false;
          });
        }
  
        if (res.consultationId) {
          this.contentIsLoading = true;
          this.api.getConsultationById(res.consultationId).then(res => {
            this.consultation = res;
            this.contentIsLoading = false;
          });
        }
  
        if (res.alertId) {
          this.contentIsLoading = true;
          this.api.Alert_GetById(res.alertId).then(res => {
            this.alert = res;
            this.contentIsLoading = false;
          });
        }
        
        if (res.type === 10) {
          this.api.getFeaturedNews(communityId)
          .then((news) => {
            this.featuredNews = news;
          });
        }
  
        if (res.type === 50) {
          const dayStart = moment(res.createdAt).startOf('day');
          const dayEnd = dayStart.clone().add(5, 'd');
  
          this.api.Events_GetByTown(communityId, dayStart.toISOString(), dayEnd.toISOString())
          .then((events) => {
            this.eventList = events;
          });
        }        
  
        this.getTextToTranslate();
        this.isLoading = false;
      });
    });
  }

  getTextToTranslate() {
    this.translations = { 
      'pushTitle': this.notif.pushTitle,
      'body': this.notif.body,
      'pushMessage': this.notif.pushMessage,
      'adminMessage': this.notif.adminCustomMessage,
    }
  }

  async ionViewDidEnter() {
    let isAuth = await this.auth.isAuthenticated();
    if (isAuth) {
      this.api.notification_MarkAsRead(this.id).then(() => {
        this.events.notificationsCountRefresh.emit();
      });
    }
  }

  async closeModal() {
    const top = await this.modalCtrl.getTop();

    if (top) {
      await this.modalCtrl.dismiss();
    } else {
      this.nav.back();
    }
  }

  async goToProposal(proposalId: number) {
    /*this.router.navigate(['/proposals/' + proposalId]).then(x => {
      this.closeModal();
    });
    */
    const modal = await this.modalController.create({
      component: ProposalDetailPage,
      componentProps: {
        id: proposalId
      }
    });
    return await modal.present();
  }

  async goToConsultation(consultationId: number) {
    /*this.router.navigate(['/consultations/' + consultationId]).then(x => {
      this.closeModal();
    });*/
    console.log(consultationId);
    const modal = await this.modalController.create({
      component: ConsultationDetailPage,
      componentProps: {
        id: consultationId
      }
    });
    return await modal.present();
  }

  async goToIncident(alertId: number) {
    /*this.router.navigate(['/incidents/' + alertId]).then(x => {
      this.closeModal();
    });*/

    const modal = await this.modalController.create({
      component: IncidentDetailPage,
      componentProps: {
        id: alertId
      }
    });
    return await modal.present();
  }

  async goToEvent(eventId: number) {
    /*this.router.navigate(['/calendar/events/' + eventId]).then(x => {
      this.closeModal();
    });*/

    const modal = await this.modalController.create({
      component: EventDetailPage,
      componentProps: {
        id: eventId
      }
    });
    return await modal.present();
  }

  async goToNew(newId: any) {
    /*this.router.navigate(['/calendar/events/' + eventId]).then(x => {
      this.closeModal();
    });*/

    const modal = await this.modalController.create({
      component: NewsDetailPage,
      componentProps: {
        id: newId
      }
    });
    return await modal.present();
  }

  urlify(text: string): string {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  }

  getTranslation(event) {
    if (this.notif.pushTitle) {
      this.notif.pushTitle = event['pushTitle'];
    }

    if(this.bodyDescription) {
      if(this.htmlDescription) {
        this.bodyDescription = this.sanitizer.bypassSecurityTrustHtml(event['body']);
      } else {
        this.bodyDescription = this.sanitizer.bypassSecurityTrustHtml(this.urlify(event['body']));
      }
    }

    if(this.notif.pushMessage) {
      this.notif.pushMessage = event['pushMessage'];
    }

    if(this.notif.adminCustomMessage) {
      this.notif.adminCustomMessage = event['adminMessage']
    }
  }

}
