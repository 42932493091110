import { EventDTO } from './../../models/EventDTO';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { TicketDTO } from 'src/app/pages/MODULES/ticketing/DTO/TicketDTO';
import { ElementTile } from './DTO/ElementTile';
import { TicketPurchaseDTO } from 'src/app/pages/MODULES/ticketing/DTO/TicketPurchaseDTO';
import { TranslateService } from '@ngx-translate/core';
import { DateIsBetweenResult, Helpers } from 'src/app/helpers';
import { NewDTO } from 'src/app/models/NewDTO';
import { InscriptionListDTO } from 'src/app/models/inscriptions/InscriptionListDTO';

@Component({
  selector: 'app-element-tile',
  templateUrl: './element-tile.component.html',
  styleUrls: ['./element-tile.component.scss'],
})
export class ElementTileComponent implements OnInit {

  @Input() event: EventDTO;
  @Input() ticket: TicketDTO;
  @Input() ticketPurchase: TicketPurchaseDTO;
  @Input() new: NewDTO;
  @Input() inscription: InscriptionListDTO;

  @Input() color: string;

  public element: ElementTile;
  public buttonText: string;

  public isDisabled  = false;

  private randColors = ['#52A43A', '#BA68C8', '#F7AA17', '#EF5350'];

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.element = new ElementTile();

    if(this.event){

      this.event.dateFrom_m = moment(this.event.dateFrom);
      this.event.dateTo_m = moment(this.event.dateTo);
      this.buttonText = '+ Info';

    } else if(this.ticketPurchase){

      this.element.title = this.ticketPurchase.ticket.eventName || this.ticketPurchase.ticket.title;
      this.buttonText = this.translate.instant('SEE');

      //console.log(this.ticketPurchase.ticket.eventDateFrom, this.ticketPurchase.ticket.eventDateTo);
      let date = Helpers.CheckIfDateIsBetween(this.ticketPurchase.ticket.eventDateFrom, this.ticketPurchase.ticket.eventDateTo);
      
      if(date == DateIsBetweenResult.After || this.ticketPurchase.isValidated){
        this.isDisabled =  true;
      }

    } else if(this.ticket){
      this.element.title = this.ticket.eventName || this.ticket.title;
      this.element.image = this.ticket.eventImages[0]?.path || this.ticket.images[0]?.path;
      
      let chkDates = Helpers.CheckIfDateIsBetween(this.ticket.saleDateFrom, this.ticket.saleDateTo);
      this.buttonText = this.translate.instant('SEE');

      switch (chkDates) {
        case DateIsBetweenResult.Before:
          this.buttonText = this.translate.instant('SCHEDULED');
          this.isDisabled =  true;
          break;
        case DateIsBetweenResult.After:
          this.buttonText = this.translate.instant('FINISHED');
          this.isDisabled =  true;
          break;
      }
    } else if(this.new){
      
      this.element.title = this.new.title;
      this.element.image = this.new.image;
      this.element.dateFrom = moment(this.new.publishDate).toISOString();
      this.buttonText = this.translate.instant('SEE');
    } else if(this.inscription) {
      this.element.title = this.inscription.name;
      const image = this.inscription.images.length === 0 ? null : this.inscription.images[0].path;
      this.element.image = image;
      let chkDates = Helpers.CheckIfDateIsBetween(this.inscription.dateOpen, this.inscription.dateClose);
      
      switch (chkDates) {
        case DateIsBetweenResult.Before:
          this.buttonText = this.translate.instant('SEE');          
          this.isDisabled = true;
          break;
        case DateIsBetweenResult.After:
          this.buttonText = this.translate.instant('FINISHED');
          this.isDisabled = true;
          break;
      }
      this.element.dateFrom = this.inscription.dateOpen;
      this.element.dateTo = this.inscription.dateClose;      
      this.buttonText = this.translate.instant('SEE');
    }
  
    if(!this.color){
      //this.color = this.randColors[this.element.id % this.randColors.length];
    }
  }
  
}
