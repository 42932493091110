<ion-content fullscreen>
  <div class="float-buttons left top">
    <a class='button' (click)="backStep()">
      <i class="fas fa-chevron-left"></i>
    </a>
  </div>

  <div *ngIf="!event" class="pulse-spinner"></div>
  <div *ngIf="event" class="content-wrapper">
    <div class="header-content">

      <ion-slides *ngIf="event.images && event.images.length" [pager]="event.images && event.images.length > 1"
        class="photos">
        <ion-slide *ngFor="let img of event.images">
          <img [src]="img | imageUrl" loading="lazy" data-fancybox="gallery" loading="lazy" data-fancybox="gallery">
        </ion-slide>
      </ion-slides>

      <!--div class="category-label">
        <div class="icon" [lazyLoad]="x.categoryImage | imageUrl"></div> <span>{{x.categoryName}}</span>
      </div-->

      <div class="float-buttons right bottom">

        <!--<a class='button like' [class.liked]="x.followedByMe" [ngClass]="likeButtonAnimation"  (click)="follow()">
          <span class='icon'>
            <div class='heart-animation-1'></div>
            <div class='heart-animation-2'></div>
          </span>
        </a>-->

        <a *ngIf="addToCalendarLoaded && canAddCalendar" class="button" fill="outline" #addCalendar (click)="buttonAnimate($event); addToCalendar()">
          <i class="fas fa-calendar-plus"></i>
        </a>

        <a class='button share' (click)="buttonAnimate($event); share();">
          <i class="fas fa-share-alt"></i>
        </a>

      </div>

    </div>

    <div class="main-content">
      <div class="body ion-padding">
        <h1>
          {{translated ? translatedTitle : event.name}}
        </h1>

        <div class="meta-info">
          
          <!-- Dates and hours -->
          <ng-container *ngIf="!event.dateTo || sameDayHours">
            <div>🗓️ {{event.dateFrom | date:'dd/MM/yyyy'}}</div>
            <div *ngIf="!event.allDay">
              🕑 {{event.dateFrom | date:"HH:mm 'h'"}}<ng-container *ngIf="event.dateTo"><span class="mx-1">-</span><span>{{event.dateTo | date:"HH:mm 'h'"}}</span></ng-container> <!-- In one line to avoid unwanted spaces -->
            </div>             
          </ng-container>
          <ng-container *ngIf="event.dateTo && !sameDayHours">
            <div *ngIf="event.allDay">🗓️ {{event.dateFrom | date:'dd/MM/yyyy'}}<span class="mx-1">-</span>{{event.dateTo | date:'dd/MM/yyyy'}}</div> <!-- In one line to avoid unwanted spaces -->
            <div *ngIf="!event.allDay">🗓️ {{event.dateFrom | date:"dd/MM/yyyy HH:mm 'h'"}}<span class="mx-1">-</span>{{event.dateTo | date:"dd/MM/yyyy HH:mm 'h'"}}</div> <!-- In one line to avoid unwanted spaces -->
          </ng-container>
          <!--  -->

          <div class="location-overflow" *ngIf="event.locationString">📍 {{event.locationString}}</div>
        </div>
        
        <hr>
        
        <ion-segment *ngIf="event.ticketId && event.ticketIsActive && (userHasTickets || (availableTickets == null || availableTickets > 0))" [value]="segmentValue" mode="ios" (ionChange)="segmentValue = $any($event).detail.value">
          <ion-segment-button value="description">
            <ion-label>{{'DESCRIPTION' | translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="tickets">
            <ion-label>{{'Modules.Ticketing.TICKETS' | translate}}</ion-label>
          </ion-segment-button>
        </ion-segment>

        <ng-container *ngIf="!event.ticketId || segmentValue == 'description'">
          <div class="margin-translate-btn">
            <app-translate-button
              [lang]="event.lang"
              [textToTranslate]="translations"
              (getTranslations)="getTranslations($event)"
            ></app-translate-button>
          </div>

          <div *ngIf="!translated" class="description" [class.html]="htmlDescription" 
            [innerHTML]="event.description">
          </div>
          <div *ngIf="translated" class="description" [class.html]="htmlDescription"
            [innerHTML]="translatedDescription">
          </div>
  
          <div *ngIf="event.locationString">
            <h2>{{'LOCATION' | translate}}</h2>
            <div>{{event.locationString}}</div><br>                     
            
            <ng-container *ngIf="event.locationLat">
              <app-map [lat]="event.locationLat" [lng]="event.locationLng" [zoom]="16"></app-map>
              <div class="ion-text-right">
                <ion-button fill="clear" (click)="openMaps()">
                  <!--<ion-icon slot="start" name="open-outline"></ion-icon>-->
                  {{ 'EVENTS.OpenMaps' | translate }}
                </ion-button>
              </div>
            </ng-container>            
          </div>

          <div *ngIf="event.externalLinks && event.externalLinks.length > 0" class="">
            <h4>{{ 'EXTERNAL_LINKS' | translate }}</h4>
            <app-external-link-list
              [links]="event.externalLinks">              
            </app-external-link-list>
          </div>

          <ng-container *ngIf="event.files.length">
            <h4>{{ 'ATTACHED_FILES' | translate }}</h4>
            <app-attached-files [files]="event.files"></app-attached-files>
          </ng-container>
  
          <div *ngIf="webScrapingElementDomain" class="source">
            {{ 'SOURCE' | translate }}: <a [href]="event.webScrapingElementUrl"
              target="_blank">{{webScrapingElementDomain}}</a>
          </div>

          <div class="bottom-buttons">
            <div *ngIf="addToCalendarLoaded && canAddCalendar" >
              <ion-button fill="clear" #addCalendarFull (click)="addToCalendar(true)">
                <i class="fas fa-calendar-plus me-2"></i>
                <span>{{ 'AddToCalendar' | translate }}</span>
              </ion-button>         
            </div>
  
            <div>
              <ion-button fill="clear" (click)="share()">
                <i class="fas fa-share-alt me-2"></i>
                <span>{{ 'SHARE' | translate }}</span>
              </ion-button>  
            </div>
          </div>

          <div class="events-subscribe" *ngIf="event.admitSubscribers && !event.ticketId">
            <div class="position-relative">
              <button *ngIf="!event.imSubscribed" class="btn btn-gray mt-4" (click)="subscribeEvent()"
                [ngClass]="loadingSubcsription? 'opacity-loading': ''">
                <i class="fa fa-user-plus"></i> {{ 'Events.INSCRIBE' | translate }} </button>
              <button *ngIf="event.imSubscribed" class="btn btn-red text-white mt-4 " (click)="unSubscribeEvent()"
                [ngClass]="loadingSubcsription? 'opacity-loading': ''">
                <i class="fa fa-user-times"></i> {{ 'Events.UNSUBSCRIBE' | translate }} </button>
              <ion-spinner *ngIf="loadingSubcsription" class="inscribed" name="dots" color="dark"></ion-spinner>
            </div>
            <div class="users-box" *ngIf="event.usersInscribe > 2">
              <p> <strong>{{event.usersInscribe}}</strong> {{ 'Events.PEOPLEINSCRIBE' | translate }}</p>
              <i class="fa fa-users"></i>
            </div>
          </div>
  
        </ng-container>        

        <ng-container *ngIf="event.ticketId">
          <app-tickets-buy-section 
            [ticketId]="event.ticketId"
            [hidden]="segmentValue != 'tickets'"
            (availability)="getAvailability($event)"
            (userHasTickets)="getUserHasTickets($event)">
          </app-tickets-buy-section>
        </ng-container>

        <div class="faces-block">
          <app-feedback-faces [type]="reactionType" [id]="event.id">
          </app-feedback-faces>
        </div>        

      </div>

    </div>

    <app-sale-button
      *ngIf="event?.ticketId && event.ticketIsActive && segmentValue == 'description'"
      [exceeded]="availableTickets != null && availableTickets < 1"      
      [callbackFunction]="setSegment"
      [dateStart]="dateStart"
      [dateEnd]="dateEnd">      
    </app-sale-button>
  </div>

</ion-content>