import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TicketPurchaseDTO } from '../../DTO/TicketPurchaseDTO';
import { TicketingService } from '../../services/ticketing-service';
import { Router } from '@angular/router';
import { TicketDTO } from '../../DTO/TicketDTO';
import Swal from 'sweetalert2';
import { TicketPriceDTO } from '../../DTO/TicketPriceDTO';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DateIsBetweenResult, Helpers } from 'src/app/helpers';
import { Stripe, PaymentSheetEventsEnum } from '@capacitor-community/stripe';
import { Capacitor } from '@capacitor/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TicketsWithCustomFieldsDTO } from "../../DTO/TicketsWithCustomFieldsDTO";
import { ModalController } from '@ionic/angular';
import { TicketFormComponent } from '../ticket-form/ticket-form.component';


@Component({
  selector: 'app-tickets-buy-section',
  templateUrl: './tickets-buy-section.component.html',
  styleUrls: ['./tickets-buy-section.component.scss']
})
export class TicketsBuySectionComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  ticketId: number;
  @Output()
  userHasTickets: EventEmitter<boolean> = new EventEmitter();
  @Output()
  availability: EventEmitter<number> = new EventEmitter();

  public model: TicketDTO;

  public inTime = false;

  public fullFree = false;
  public subtotal = 0;
  public totalWithFee = 0;
  public totalQty = 0;
  public totalQtyPurchases = 0;
  public canDismiss = true;
  public maxTiquetsPerUser: number;
  public formGroup: FormGroup;
  public myTickets: TicketPurchaseDTO[] = [];
  public isPurchaseLoading = false;
  public availableTickets: number | null = null;

  private refreshRemainingInterval: any;


  constructor(
    private srv: TicketingService,
    private auth: AuthService,
    private translate: TranslateService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private modalCtrl: ModalController
  ) {

  }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({});
    this.reload();

    this.refreshAvailability();
    this.refreshRemainingInterval = setInterval(() => {
     this.refreshAvailability();
    }, 10000);

    /*Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
      console.log('PaymentSheetEventsEnum.Completed');
    });
    Stripe.addListener(PaymentSheetEventsEnum.Loaded, () => {
      console.log('PaymentSheetEventsEnum.Loaded');
    });
    Stripe.addListener(PaymentSheetEventsEnum.Canceled, () => {
      console.log('PaymentSheetEventsEnum.Canceled');
    });
    Stripe.addListener(PaymentSheetEventsEnum.FailedToLoad, (err) => {
      console.log('PaymentSheetEventsEnum.FailedToLoad', err);
    });
    Stripe.addListener(PaymentSheetEventsEnum.Failed, (err) => {
      console.log('PaymentSheetEventsEnum.Failed', err);
    });*/
  }
  
  ngAfterViewInit(): void {

  }

  ngOnDestroy(){
    clearInterval(this.refreshRemainingInterval)
  }

  reload(){
    this.srv.getById(this.ticketId).then((res) => {
      this.model = res;
      for (const customField of this.model.customFields) {
        if(customField.required){
          this.formGroup.addControl(customField.id.toString(), this._formBuilder.control(null, Validators.required));
        }else{
          this.formGroup.addControl(customField.id.toString(), this._formBuilder.control(null));
        }
      }
      this.fullFree = res.prices.every(x => x.totalWithFee == 0);

      let chkDates = Helpers.CheckIfDateIsBetween(res.saleDateFrom, res.saleDateTo);
      this.inTime = (chkDates == DateIsBetweenResult.Between);

      this.model.prices.forEach(p => {
        p.qty = 0;
      });

      this.subtotal = 0;
      this.totalWithFee = 0;
      this.totalQty = 0;

      this.reloadMaxTickets();
    });
    
    this.reloadMyTickets();
  }

  refreshAvailability(){
    this.srv.getAvailability(this.ticketId).then((res) => {
      this.availableTickets = res;
      this.availability.emit(this.availableTickets);
    }).catch(() => {})
  }

  reloadMyTickets(){
    this.auth.isAuthenticatedObs().subscribe((res) => {
      if(res){
        let user = this.auth.getUser();
        if(user.roles.includes('User')){
          this.srv.getMyTickets(this.ticketId).then((res) => {
            this.myTickets = res;
            this.totalQtyPurchases = res
            .reduce((sum, current) => sum + (current.totalQuantity || 0), 0);            
            this.userHasTickets.emit(this.myTickets.length > 0);
            this.reloadMaxTickets();
          });
        } else {
          this.myTickets = [];
          this.userHasTickets.emit(false);
        }
      } else {
        this.myTickets = [];
        this.userHasTickets.emit(false);
      }
    });
  }

  reloadMaxTickets(){
    if(this.model?.maxByUser){
      this.maxTiquetsPerUser = this.model.maxByUser - (this.totalQty + this.totalQtyPurchases);      
    }
  }

  public async openModal() {
    const modal = await this.modalCtrl.create({
      component: TicketFormComponent,
      componentProps: {
        price: this.totalWithFee,
        ticket: this.model
      }
    });

    modal.present();

    const { role } = await modal.onWillDismiss();    

    if(role === 'confirm') {
      this.purchase()
    }
  }
  
  onChangeQty(p: TicketPriceDTO){
    p.subtotal = p.total * p.qty;
    p.subtotalWithFee = p.totalWithFee * p.qty;
    
    this.subtotal = this.model.prices
      .reduce((sum, current) => sum + (current.subtotal || 0), 0);

    this.totalWithFee = this.model.prices
      .reduce((sum, current) => sum + (current.subtotalWithFee || 0), 0);

    this.totalQty = this.model.prices
      .reduce((sum, current) => sum + (current.qty || 0), 0);
  }
  public hasCustomField(){
    if (this.model.customFields.length) {
        this.openModal();
    }else{
      this.purchase();
    }
  }
  save(){


    if (this.formGroup.valid) {
      this.purchase();
    }
  }
 
  async purchase(){
    if (this.isPurchaseLoading) return;
    
    let isAuth = await this.auth.isAuthenticated();

    if (!isAuth){
      this.translate.get(['Oops', 'LOGIN_REQUIRED', 'LOGIN', 'CANCEL'])
      .subscribe(t => {

        Swal.fire({
          title: t['Oops'],
          text: t['LOGIN_REQUIRED'],
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: t['LOGIN'],
          cancelButtonText: t['CANCEL'],
          reverseButtons: true
        })
        .then((result) => {
          if (result.value) {
            this.router.navigate(['/login']);
          }
        });
        
      });

      return;
    }

    this.isPurchaseLoading = true;
    var customFieldsAnddetails = new TicketsWithCustomFieldsDTO();
    customFieldsAnddetails.details = this.model.prices
      .filter(x => x.qty && x.qty != 0)
      .map(x => ({ 
        quantity: x.qty, 
        ticketPriceId: x.id,
      }));
    customFieldsAnddetails.customFields = this.model.customFields;

    this.srv.purchase(this.model.id, customFieldsAnddetails)
    .then(async (res) => {
      if (res?.result === 'stripe') {

        this.stripePayment(res.ticketPurchaseUuid, res.stripeData);

      } else if (res?.result === 'ok') {
        this.isPurchaseLoading = false;
        this.reload();

        Swal.fire({
          title: this.translate.instant('GOOD'),
          text: this.translate.instant('Modules.Ticketing.BUY_SUCCESS'),
          icon: 'success',
          //confirmButtonText: t['ACCEPT'],
        });

      } else {
        this.isPurchaseLoading = false;
        const t = await this.translate.get(['Oops', 'Errors.GENERIC', 'ACCEPT']).toPromise();
        Swal.fire({
          title: t.Oops,
          text: t['Errors.GENERIC'],
          icon: 'error',
          confirmButtonText: t.ACCEPT,
        });
      }
    })
    .catch(() => {
      this.isPurchaseLoading = false;
    });
  }

  private async stripePayment(ticketPurchaseUuid: string, stripeData: any){

    if(!Capacitor.isNativePlatform()){
      Stripe.addListener(PaymentSheetEventsEnum.Loaded, () => {
        this.isPurchaseLoading = false;
      });
    }

    let fail = async (message?: string) => {
      this.isPurchaseLoading = false;

      const t = await this.translate.get(['Oops', 'ACCEPT']).toPromise();
      Swal.fire({
        title: t.Oops,
        text: message || (this.translate.instant('Modules.Ticketing.ErrorPayment')),
        icon: 'error',
        confirmButtonText: t.ACCEPT,
      });
    };
    
    await Stripe.createPaymentSheet({
      paymentIntentClientSecret: stripeData.paymentIntent,
      customerId: stripeData.customerId,
      customerEphemeralKeySecret: stripeData.ephemeralKey,
      merchantDisplayName: 'Wecoo',
      withZipCode: false
    });
    
    Stripe.presentPaymentSheet().then((result) => {
      if (result.paymentResult === PaymentSheetEventsEnum.Completed) {
        this.isPurchaseLoading = true;
        this.srv.checkPayment(ticketPurchaseUuid)
        .then((res) => {
          this.isPurchaseLoading = false;

          if(res.result == 'ok'){
            this.reload();
            Swal.fire({
              title: this.translate.instant('GOOD'),
              text: this.translate.instant('Modules.Ticketing.TICKETS_BUY_SUCCESS'),
              icon: 'success',
              //confirmButtonText: t['ACCEPT'],
            });
          } else {
            fail(res.error.message);
          }
        })
        .catch((err) => {
          fail();
        });
      } else {
        fail();
      }
    })
    .catch((err) => {});
    
  }

}
