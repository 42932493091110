import { TownModel } from './models/TownModel';
import { ThemeService } from './services/theme/theme.service';
import { CommunityModulesService } from './services/community-modules/community-modules.service';
import { StorageService } from 'src/app/services/storage.service';
import { DeviceRegistrationDTO } from './models/DeviceRegistrationDTO';
import { Component, EventEmitter, NgZone, OnInit, ViewChild } from '@angular/core';
import { Platform, NavController, MenuController, ToastController, ModalController, ActionSheetController, 
  PopoverController, IonRouterOutlet, ActionSheetButton} from '@ionic/angular';
import { LanguageService } from './services/language.service';
import { Storage } from '@ionic/storage';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { ApiService } from './services/api.service';

import Swal from 'sweetalert2';
import { UserModel } from './models/UserModel';

import { EventsService } from './services/events.service';
import { DetailPage } from './pages/notifications/detail/detail.page';
import { CommunityType, STORAGE_TUTORIAL } from 'src/constants';
import { STRIPE_PUBKEY, environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HtmlComponent } from './components/html/html.component';
import { AnalyticsService } from './services/analytics.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { StatusBar, Style } from '@capacitor/status-bar';
import party from 'party-js';
import { AnnouncementDTO } from './models/AnnouncementDTO';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import { CommunityService } from './services/community/community.service';
import { Stripe } from '@capacitor-community/stripe';
import { first } from 'rxjs/operators';
import { Fancybox } from '@fancyapps/ui';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;

  //private DEFAULT_TOWN = null;

  public town: TownModel;
  public appTypeClass = '';
  public communityClass = '';

  private exitCounter = 0;

  public popup: AnnouncementDTO;

  public user: UserModel;
  public notificationsRefresh: EventEmitter<any> = new EventEmitter();

  public isDeveloper = false;
  public isAdmin = false;
  public isUser = false;

  public isEnvDev = false;

  public modules = {};

  public proposalsModeVote = true;
  public dynamicName = 'PROPOSAL';

  private registerDeviceFunc: () => void;
  private unRegisterDeviceFunc: () => void;

  public versionLabel: string;
  public easterEggCounter = 0;

  @ViewChild('step1') step1: any; 

  public cssVariables = null;

  private oldUrlsPathStats = ['incidents', 'proposals', 'calendar', 'events', 'towns', 'news'];

  constructor(
    private zone: NgZone,
    private platform: Platform,
    public languageService: LanguageService,
    private storage: Storage,
    private storageSvc: StorageService,
    private router: Router,
    public navCtrl: NavController,
    public auth: AuthService,
    public api: ApiService,
    public menuCtrl: MenuController,
    private toastController: ToastController,
    private events: EventsService,
    private modalController: ModalController,
    public actionSheetController: ActionSheetController,
    private translate: TranslateService,
    public popoverController: PopoverController,
    private analyticsService: AnalyticsService,
    private modulesSvc: CommunityModulesService,
    public theme: ThemeService,
    private community: CommunityService,
    private ngZone: NgZone
  ) {

    this.initializeCapacitorApp(); 

    if (STRIPE_PUBKEY) {
      Stripe.initialize({
        publishableKey: STRIPE_PUBKEY,
      });  
    }

    this.versionLabel = `${environment.version}`;

    if (environment.tag) {
      
      if (environment.tag !== 'prod') {
        this.versionLabel += ` (${environment.tag})`;
      }

      if (environment.tag === 'dev') { 
        this.isEnvDev = true; 
      }
    }

    Fancybox.defaults = {
      ...Fancybox.defaults,
        mainClass: 'fancybox-margin',
       Toolbar: {
        display: {
            middle: [],
            left: ["infobar"],
            right: ["toggleZoom", "close"],
        },
      },
    };
    
  }

  initializeCapacitorApp() {

    this.platform.ready().then(() => {

      //this.nfcSetup();

      if (this.platform.is('capacitor')) {
        //this.statusBar.styleDefault();
        
        if (Capacitor.getPlatform() === 'android') {
          StatusBar.setOverlaysWebView({ overlay: false })
        }

        StatusBar.setStyle({
          style: Style.Dark
        });

        this.setupDeeplinks();

        //this.statusBar.backgroundColorByHexString('#99BD73');

        /*App.addListener('backButton', (data) => {
          console.log(data);
          const url = this.router.url;
          console.log(url);
          if(url == '/tabs/home'){
            if (this.exitCounter === 0) {
              this.exitCounter++;
              this.exitToast();
              setTimeout(() => { this.exitCounter = 0; }, 3000);
            } else {
              (navigator as any).app.exitApp();
            }
          }
        });*/

        this.platform.backButton.subscribeWithPriority(100, (processNextHandler) => {
          if (Swal.isVisible()){
            Swal.close();
          }else{
            const fancybox = Fancybox.getInstance();
            console.log(fancybox);
            if(fancybox){
              fancybox.close();
            } else {
              processNextHandler();
            }
          }
        });

        this.platform.backButton.subscribeWithPriority(-1, (processNextHandler) => {
          const url = this.router.url;
          
          if (!this.routerOutlet.canGoBack()) {
            if (url === '/tabs/home'){
              if (this.exitCounter === 0) {
                this.exitCounter++;
                this.exitToast();
                setTimeout(() => { this.exitCounter = 0; }, 3000);
              } else {
                App.exitApp();
              }
            }else{
              this.router.navigate(['/tabs/home']);
            }
          }
        });

        this.pushSetup();

        /*
        App.getInfo().then((res) => {
          this.versionLabel = `${res.version}`;

          if (environment.tag && environment.tag !== 'prod') {
            this.versionLabel += ` (${environment.tag})`;
          }
        });
        */
      }

      Fancybox.bind("[data-fancybox]");
      
      this.community.GetAppIdDefaultCommunityCode()
      .then(townCode => {
        if(townCode){
          this.api.Town_GetByCode(townCode)
          .then((newRes => {
            this.storageSvc.SetTown(newRes);
          }));
        }
      });
      
    });

    this.languageService.setInitialAppLanguage();

    this.auth.isAuthenticatedObs().subscribe((res) => {
      if (res) {
        this.user = this.auth.getUser();
        this.isDeveloper = this.user.roles.includes('Developer');
        this.isAdmin = this.user.roles.includes('Admin');
        this.isUser = this.user.roles.includes('User');
      } else {
        this.user = null;
        this.isDeveloper = false;
        this.isAdmin = false;
        this.isUser = false;
      }
    });

    this.events.showPopUp.subscribe((res) => {
      this.popup = res;
    });
  }

  async ngOnInit() {

    this.analyticsService.startTrackerWithId('G-908MY37DPV');

    this.router.events
    .subscribe(event => {
      //console.log(event);
      if (event instanceof NavigationEnd) {
        this.analyticsService.trackView(event.urlAfterRedirects, event.urlAfterRedirects);
        
        setTimeout(() => {
          SplashScreen.hide({ fadeOutDuration: 1000 });
        }, 1000);
      }
    });

    this.events.activateTutorial.subscribe(() => {
      this.Tutorial_ShowStep(0);
    });

    this.storageSvc.GetTownParameters().subscribe((res) => {
      if(res){
        this.proposalsModeVote = res["PROPOSALS.MODE"] != 'SUGGESTION';
        this.dynamicName = res['PROPOSALS.DYNAMIC_NAME'] ?? 'PROPOSAL';
      }
    });

    this.community.GetCommunityType().subscribe((res) => {
      if(res == CommunityType.Town){
        this.appTypeClass = 'app-wetown';
      } else {
        this.appTypeClass = 'app-wecoo';
      }
    });

    this.community.Get()
    .subscribe((res) => {
      this.town = res;

      this.communityClass = res != null ? 'community-' + res.code : ''; 
      this.cssVariables = ThemeService.getThemeCssVariablesByCommunity(res);

      if(res){
        this.modulesSvc.getDict(res.id, true).subscribe((modules) => {
          this.modules = modules;
        });
      }
    });
    
  }

  LogIn() {
    this.menuCtrl.close().then(x => {
      this.router.navigate(['/login']);
    });
  }

  async LogOut() {
    const t = await this.translate.get(['SURE?', 'Session.LOG_OUT_CONFIRMATION', 'EXIT', 'CANCEL']).toPromise();
    Swal.fire({
      title: t['SURE?'],
      text: t['Session.LOG_OUT_CONFIRMATION'],
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t.EXIT,
      cancelButtonText: t.CANCEL,
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        this.DoLogOut();
      }
    });
  }

  DoLogOut() {
    if (this.unRegisterDeviceFunc) this.unRegisterDeviceFunc();

    this.auth.logout().then(() => {
      this.menuCtrl.close();
      this.router.navigate(['/']);
    });
  }

  GoToUserPage() {
    this.menuCtrl.close().then(x => {
      this.router.navigate(['/tabs/user']);
    });
  }

  menuOpened() {
    this.notificationsRefresh.emit();
  }

  async presentLanguageActionSheet() {
    const t = await this.translate.get(['CANCEL']).toPromise();

    let buttons : ActionSheetButton[] = [];
    
    this.languageService.available_languages.forEach(el => {
      buttons.push({
        text: el.text,
        handler: () => { this.changeLanguage(el.key); }
      });
    });

    buttons.push( {
      text: t.CANCEL,
      role: 'cancel'
    });


    const actionSheet = this.actionSheetController.create({buttons}).then(x => x.present());
  }

  changeLanguage(lang: string) {
    this.languageService.setLanguage(lang);
  }

  openUrl(url: string) {
    this.events.openUrl(url);
  }

  openTypeForm() {
    this.community.GetCommunityType().subscribe((res) => {
      if(res == CommunityType.Town){
        if (this.languageService.selected == 'ca'){
          this.openUrl('https://rp25a3yz0ji.typeform.com/to/QXuXPW9q')
        } else {
          this.openUrl('https://rp25a3yz0ji.typeform.com/to/wmjP8uUG')
        }
      } else {
        this.openUrl('https://rp25a3yz0ji.typeform.com/to/HUJPaznq')
      }
    });
  }

  async presentToastNotification(notification: PushNotificationSchema) {
    const toast = await this.toastController.create({
      header: notification.title,
      message: notification.body,
      duration: 3000,
      position: 'top',
      buttons: [
        {
          text: 'Veure',
          handler: () => {
            this.PushNotificationActionPerformed(notification);
          }
        }
      ]
    });
    toast.present();
  }

  private pushSetup() {

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
      'registration',
      async (token: Token) => {
        console.log('Push registration success. Native token: ' + token.value);

        let finalToken = token.value;

        if (Capacitor.getPlatform() === 'ios') {
          const tokenFcm = await FCM.getToken();
          finalToken = tokenFcm.token;
          console.log('FCM token: ' + tokenFcm.token);
        }

        console.log('FINAL PUSH token: ' + finalToken);

        const id = await Device.getId();
        const device = await Device.getInfo();
        const app = await App.getInfo();
        
        const registerDTO = {
          registrationId: finalToken,
          uuid: id.identifier,

          //Obsolete
          //model: info.model,
          //platform: info.platform,
          //version: app.version,

          //new
          device: {
            name: device.name,
            model: device.model,
            platform: device.platform,
            operatingSystem: device.operatingSystem,
            osVersion: device.osVersion,
            manufacturer: device.manufacturer,
            webViewVersion: device.webViewVersion
          },
          app: app
        } as DeviceRegistrationDTO;
  
        this.registerDeviceFunc = () => {
          this.api.notification_registerDevice(registerDTO);
        };
  
        this.unRegisterDeviceFunc = () => {
          this.api.notification_unregisterDevice(finalToken);
        };
  
        this.auth.isAuthenticatedObs().subscribe((state) => {
          if (state) {
            this.registerDeviceFunc();
          }
        });
      },
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      console.error('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        this.events.notificationsCountRefresh.emit();

        this.presentToastNotification(notification);
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (actionPerformed: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(actionPerformed));
        this.ngZone.run(() => this.PushNotificationActionPerformed(actionPerformed.notification));
      },
    );
  }

  private PushNotificationActionPerformed(notification: PushNotificationSchema){
    if (notification.data.appRoute) {
      let newRoute = notification.data.appRoute;
      this.router.navigateByUrl(newRoute)
      .then(data => { })
      .catch(e => {
        console.log('Route ' + +'not found, redirection stopped with no error raised');
      });
    } else if (notification.data.notificationID) { 
      // this.router.navigate(['/notification/' + notification.data.notificationID]);
      this.openNotification(parseInt(notification.data.notificationID)); 
    }
  }

  /*
  private nfcSetup(){
    console.log('nfcSetup');
    const flags = this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V;
    let readerMode$ = this.nfc.readerMode(flags).subscribe(
          tag => {
            //console.log(tag);
            let payload = tag.ndefMessage[0].payload;
            let type = payload.shift();
            let tagContent = this.nfc.bytesToString(payload);
            //let msg = this.ndef.decodeMessage(tag.ndefMessage[0].payload);
            //console.log(tagContent);
            const slug = tagContent.split('.app').pop();
            if (slug) {
                this.router.navigateByUrl(slug);
            }
          },
          err => console.log('Error reading tag', err)
      );

    this.nfc.addTagDiscoveredListener((tagEvent: Event) => {
      console.log(tagEvent);
    });
    this.nfc.addNdefListener((tagEvent: Event) => {
      console.log(tagEvent);
    });
  }
*/
  async openNotification(notificationId: number) {
    const modal = await this.modalController.create({
      component: DetailPage,
      componentProps: {
        id: notificationId
      }
    });
    return await modal.present();
  }

  async Tutorial_ShowStep(num: number) {
    const el = document.getElementById(`tutorial-step${num + 1}`);

    if (!el && num > 5) {
      this.Tutorial_Finish();
      return;
    }

    const t = await this.translate.get(`Tutorial.${num}`).toPromise();
    
    const ev = new Event('custom');
    Object.defineProperty(ev, 'target', {value: el });

    const popover = await this.popoverController.create({
      component: HtmlComponent,
      componentProps: {html: t},
      cssClass: 'custom-popover',
      event: ev,
      mode: 'ios'
    });
    await popover.present();

    await popover.onDidDismiss().then((role) => {
      console.log('onDidDismiss resolved with role', role);
      this.Tutorial_ShowStep(num + 1);
    });
  }

  async Tutorial_Finish() {
    this.storage.set(STORAGE_TUTORIAL, true);
  }

  async exitToast() {
    const res = await this.translate.get('PRESS_AGAIN_TO_EXIT').toPromise();

    const toast = await this.toastController.create({
      message: res,
      duration: 3000,
      position: 'bottom',
    });
    toast.present();
  }

  public easterEgg(event: any){
    if (++this.easterEggCounter > 5) {
      const target = event.target || event.srcElement || event.currentTarget;
      party.confetti(target, {
        count: party.variation.range(0, 20),
      });
    }
  }

  private setupDeeplinks(){
    App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        // Example url: https://link.wecoo.app/communityCode/news/32542
        // path = /communityCode/news/32542
        console.log(data);
        console.log(data.url);
        //const path = data.url.split('.app').pop();

        const regex = /http(?:s?):\/\/(?:[\w.-]+)\/([\w-_]+)(?:\/?)(.*)/;
        const found = data.url.match(regex);

        if(found && found[1]){
          // Old retrocompatibility
          if(this.oldUrlsPathStats.find(x => found[1].startsWith(x))){
            this.router.navigateByUrl('/' + found[1] + '/' + found[2])
            .finally(() => {
              SplashScreen.hide({ fadeOutDuration: 1000 });
            });
            return;
          }
          // ----

          let communityCode = found[1];
          let navigate = () => {
            let path = found[2];

            if(path){
              this.router.navigateByUrl('/' + path)
              .finally(() => {
                SplashScreen.hide({ fadeOutDuration: 1000 });
              });
            }
          }

          let currentCommunity = await this.community.Get().pipe(first()).toPromise();
          
          if(currentCommunity.code == communityCode){
            navigate();
            return;
          }

          let isGenericApp = await this.community.IsGenericApp().pipe(first()).toPromise();
          if(!isGenericApp){
            // Do nothing
            return;
          }
          
          let newCommunity = await this.api.Town_GetByCode(communityCode);

          if(!newCommunity){
            const t = await this.translate.get(['Oops', 'Errors.GENERIC', 'ACCEPT']).toPromise();
            Swal.fire({
              title: t.Oops,
              text: t['Errors.GENERIC'],
              icon: 'error',
              confirmButtonText: t.ACCEPT,
            });
            return;
          }

          let gotoAddCommunity = () => {
            Swal.fire({
              title: 'Canvi de comunitat',
              text: `El contingut que intentes obrir pertany a la comunitat de '${newCommunity.name}'. Vols unir-te?`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'SI',
              cancelButtonText: 'CANCELAR',
              reverseButtons: true
            })
            .then((result) => {
              if (result.value) {
                this.router.navigateByUrl('/community-selector');
              }
            });
          };

          let gotoChangeCommunity = () => {
            Swal.fire({
              title: 'Canvi de comunitat',
              text: `El contingut que intentes obrir pertany a la comunitat de '${newCommunity.name}'. Vols canviar de comunitat?`,
              icon: 'question',
              showCancelButton: true,
              confirmButtonText: 'SI',
              cancelButtonText: 'CANCELAR',
              reverseButtons: true
            })
            .then(async (result) => {
              if (result.value) {
                newCommunity.env = environment.tag;
                await this.storageSvc.SetTown(newCommunity);
                this.modulesSvc.get(newCommunity.id, true).subscribe();
                navigate();
              }
            });
          };

          var isAuthenticated = await this.auth.isAuthenticatedObs().pipe(first()).toPromise();
          if(isAuthenticated){
            this.api.GetMyTowns(currentCommunity.communityType).then(townsList => {
              if(townsList.find(x => x.code == newCommunity.code)){
                gotoChangeCommunity();
              } else {
                gotoAddCommunity();
              }
            });
          } else {
            gotoAddCommunity();
          }
          
        }

        // If no match, do nothing - let regular routing
        // logic take over
        //console.log("https://wetown.app/tabs/tab2");
      });
    });
  }

}
