import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Share } from '@capacitor/share';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { EventDTO } from 'src/app/models/EventDTO';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import Swal from "sweetalert2";
import {AuthService} from "../../../auth/auth.service";
import {TranslateService} from "@ngx-translate/core";
import { CommunityService } from 'src/app/services/community/community.service';
import { DateIsBetweenResult, Helpers } from 'src/app/helpers';
import { Fancybox } from '@fancyapps/ui';
import { ReactionType } from 'src/constants';
import { LanguageService } from 'src/app/services/language.service';
import { DateTime } from 'luxon';
import { atcb_action } from "add-to-calendar-button";
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class DetailPage implements OnInit {

  @ViewChild('addCalendar') addCalendar: ElementRef;
  @ViewChild('addCalendarFull') addCalendarFull: ElementRef;

  @Input() id: number;

  public webScrapingElementDomain: string;

  public loadingSubcsription;
  public event: EventDTO;
  public htmlDescription: boolean;

  public segmentValue = 'description';

  reactionType = ReactionType.Event;

  public env = environment;

  public ticketInTime = false;

  public sameDayHours = false;

  public translated = false;
  translatedTitle: string = null;
  translatedDescription: string = null;
  lang: string;
  showTranslated: boolean;

  public addToCalendarLoaded = false;
  public canAddCalendar = true;
  public addToCalendarData = {};
  public availableTickets: number | null = null;
  public userHasTickets = true;
  public dateStart: Date;
  public dateEnd: Date;

  translations = {};
  
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private nav: NavController,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private translate: TranslateService,
    private router: Router,
    private communityService: CommunityService,
    private platform: Platform,
    private languageService: LanguageService,
    private renderer: Renderer2,
    private df: ChangeDetectorRef
  ) { }

 
  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.id = parseInt(id);
    }
    this.reload();
  }
  reload(){
    this.api.Events_GetById(this.id).then((res: EventDTO) => {
      this.event = res;

      this.getTextToTranslate();
      this.setCalendarButton();
      this.lang = this.languageService.getLanguage();     
      
      if (this.event.lang){
        this.showTranslated = this.event.lang != this.lang;
      }

      if (res.description && res.description.indexOf('<p>') !== -1) {
        this.htmlDescription = true;
      }
      this.loadingSubcsription = false;

      if(res.ticketId){
        this.ticketInTime = Helpers.CheckIfDateIsBetween(res.ticketSaleDateFrom, res.ticketSaleDateTo) == DateIsBetweenResult.Between;

        this.dateStart = this.convertToJsDate(this.event.ticketSaleDateFrom);
        this.dateEnd = this.convertToJsDate(this.event.ticketSaleDateTo);
      }

      this.sameDayHours = EventDTO.sameDayHours(res);

      this.webScrapingElementDomain = Helpers.GetOnlyTheHostFromUrl(this.event.webScrapingElementUrl);
    });

  }

  getTextToTranslate() {
    this.translations = { 'name': this.event.name, 'description': this.event.description }    
  }

  ionViewDidEnter() {
   
  }

  async backStep() {
    const top = await this.modalCtrl.getTop();
    if (top) {
      await this.modalCtrl.dismiss();
    } else {
      this.nav.back();
    }
  }

  buttonAnimate(event: MouseEvent){
    this.renderer.addClass(event.target, 'animate');

    setTimeout(() => {
      this.renderer.removeClass(event.target, 'animate');
    }, 1000);
  }

  async share(){
    const t = await this.translate.get(['SHARE']).toPromise();
    const shareLink = await this.communityService.MakeShareLink('/events/' + this.event.id);

    let shareRet = await Share.share({
      title: '',
      text: '',
      url: shareLink,
      dialogTitle: t.SHARE
    });

    console.log(shareRet);
  }

  async subscribeEvent(){
    let isAuth = await this.auth.isAuthenticated();
    if (!isAuth) {
      this.translate.get(['Oops', 'Events.LOGIN_REQUIRED', 'LOGIN', 'CANCEL'])
          .subscribe(t => {
            Swal.fire({
              title: t.Oops,
              text: t['Events.LOGIN_REQUIRED'],
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: t.LOGIN,
              cancelButtonText: t.CANCEL,
              reverseButtons: true
            })
                .then((result) => {
                  if (result.value) {
                    this.router.navigate(['/login']);
                  }
                });
          });
      return;
    }

    this.loadingSubcsription = true;
    this.api.SubscribeEvents(this.id).then(() => {
      this.reload();
    });
  }
  
  unSubscribeEvent(){
    this.loadingSubcsription = true;
    this.api.UnSubscribeEvents(this.id).then(() => {
     this.reload();
    });
  }

  openMaps() {    
    if (this.platform.is('ios')) {
      window.open('maps://?q=' + this.event.locationLat + ',' + this.event.locationLng, '_system');
    }
    if (this.platform.is('android')) {
      window.open('geo://' + this.event.locationLat + ',' + this.event.locationLng + '?q=' + this.event.locationLat + ',' + this.event.locationLng + '(' + this.event.locationString + ')', '_system');
    }
    if (this.platform.is('desktop')) {
      window.open('https://www.google.com/maps?q=' + this.event.locationLat + ',' + this.event.locationLng);
      return;
    }
  }

  getTranslations(event) {
    this.event.name = event['name'];
    this.event.description = event['description'];
  }

  public getAvailability(event: number) {
    this.availableTickets = event;        
    this.df.detectChanges();
  }

  public getUserHasTickets(event: boolean) {
    this.userHasTickets = event;    
    this.df.detectChanges();
  }

  public addToCalendar(fullButton?: boolean) {
    atcb_action(this.addToCalendarData, fullButton ? this.addCalendarFull.nativeElement : this.addCalendar.nativeElement)
  }

  private setCalendarButton() {
    const name = this.event.name;
    const dateFrom = DateTime.fromJSDate(new Date(this.event.dateFrom));
    const startDate = dateFrom.toISODate();
    let endDate, startTime, endTime, locationString, dateTo;
  
    if(this.event.dateTo != null) {
      dateTo = DateTime.fromJSDate(new Date(this.event.dateTo))
      endDate = dateTo.toISODate();
      this.canAddCalendar = dateTo > DateTime.now();     
    } else {
      endDate = startDate;
      this.canAddCalendar = dateFrom > DateTime.now();     
    }

    if(!this.event.allDay) {            
      startTime = dateFrom?.toFormat('HH:mm') ?? null;
      endTime = dateTo?.toFormat('HH:mm') ?? null;
    } else {        
      startTime = null;
      endTime = null;
    }

    if(this.event.locationString && this.event.locationString != null) {
      locationString = this.event.locationString;
    }
    
    let options = ['Google', 'Apple', 'Outlook.com'];

    if (Capacitor.getPlatform() === 'ios') {
      options = ['Google', 'Apple', 'Outlook.com'];
    } else if (Capacitor.getPlatform() === 'android') {
      options = ['Google', 'Outlook.com'];
    }

    this.addToCalendarData = {
      name: name,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      location: locationString,
      timeZone: 'Europe/Madrid',
      options: options,
      inline: true, 
      buttonStyle: 'round',
      customLabels: { 'close': this.translate.instant('Close') }
    }

    this.addToCalendarLoaded = true;
  }

  public setSegment = (args: any): void => {
    this.segmentValue = 'tickets'
  }

  private convertToJsDate(date: string) {
    return DateTime.fromISO(date).toJSDate();
  }
}
